<template>
  <div>
    <el-switch
      class='ml-20'
      v-model='operatingMode'
      active-text='investment'
      active-value='investment'
      inactive-text='case'
      inactive-value='case'>
    </el-switch>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'CompanySettings',
  computed: {
    ...mapFields('companySettings', {
      companySettings: 'companySettings',
      operatingMode: 'companySettings.operating_mode',
    }),
    ...mapState('users', [
      'currentUserInfo'
    ]),
    isBbridgeAdmin () {
      return this.currentUserInfo.roles.includes('bbridge')
    },
  },
  watch: {
    operatingMode: {
      handler: function () {
        this.submitSettings()
      },
    },
  },
  methods: {
    ...mapActions('companySettings', [
      'createUpdateCompanySettings',
    ]),
    submitSettings () {
      this.createUpdateCompanySettings(this.companySettings)
    }
  },
  mounted () {
    if (!this.isBbridgeAdmin) {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
